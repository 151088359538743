.MuiFormControlLabel-root {
  margin-left: 0 !important;
  .MuiTypography-root {
    margin-left: 8px !important;
    @include font(12px, normal, 600, var(--text-2));
  }
  .MuiCheckbox-root {
    padding: 0 !important;
    &.MuiCheckbox-colorPrimary {
      .MuiSvgIcon-root {
        color: var(--text-4);
      }
    }
    &.Mui-checked {
      &.MuiCheckbox-colorPrimary {
        .MuiSvgIcon-root {
          color: var(--primary);
        }
      }
    }
  }
}
