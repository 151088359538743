button.MuiButton-root {
  text-transform: unset !important;
  box-shadow: none !important;
  @include font(14px, 24px !important, 600, var(--white));
  border-radius: $radius6 !important;

  &.MuiButton-contained {
    &.MuiButton-containedPrimary {
      @include button-color(
        var(--white) !important,
        var(--primary) !important,
        var(--primary) !important
      );
    }

    &.MuiButton-containedSecondary {
      @include button-color(
        var(--white) !important,
        var(--secondary) !important,
        var(--secondary) !important
      );
    }

    &.MuiButton-containedError {
      @include button-color(
        var(--white) !important,
        var(--error) !important,
        var(--error) !important
      );
    }

    &.Mui-disabled {
      color: var(--text-4) !important;
      background: var(--border-3) !important;
    }
  }

  &.MuiButton-header {
    @include button-color(
      var(--text-2) !important,
      var(--white-opacity-07) !important,
      var(--white-opacity-07) !important
    );

    &:hover,
    &:focus {
      color: var(--text-1) !important;
    }
  }

  &.MuiButton-outlined {
    &.MuiButton-outlinedPrimary {
      @include button-color(
        var(--primary) !important,
        var(--transparent) !important,
        var(--primary) !important
      );
    }

    &.MuiButton-outlinedSecondary {
      @include button-color(
        var(--secondary) !important,
        var(--transparent) !important,
        var(--secondary) !important
      );
    }

    &.MuiButton-outlinedError {
      @include button-color(
        var(--error) !important,
        var(--transparent) !important,
        var(--error) !important
      );
    }

    &.MuiButton-LandingPage {
      @include button-color(
        var(--primary) !important,
        var(--transparent) !important,
        var(--primary) !important
      );

      &:hover,
      &:focus {
        @include button-color(
          var(--black) !important,
          var(--primary) !important,
          var(--primary) !important
        );
      }
    }

    &.MuiButton-outlinedInherit {
      @include button-color(var(--text-1), var(--transparent), var(--border-1));

      &:hover,
      &:focus {
        background: var(--white-opacity-05) !important;
      }
    }

    &.MuiButton-outlinedError {
      @include button-color(var(--error), var(--transparent), var(--error));

      &:hover,
      &:focus {
        background: var(--error-opacity-07) !important;
      }
    }

    &.Mui-outline-light {
      @include button-color(
        var(--text-3) !important,
        var(--transparent) !important,
        var(--border-3) !important
      );
    }

    &.Mui-disabled {
      color: var(--text-4) !important;
      border: none !important;
      background: var(--border-3) !important;
    }

    &.Mui-add-client {
      color: var(--primary-700) !important;
    }
  }

  &.MuiButton-sizeSmall {
    height: 32px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px !important;

    .MuiButton-iconSizeSmall {
      font-size: 12px !important;
    }

    .MuiButton-startIcon {
      margin-right: 6px !important;
    }
  }

  &.MuiButton-sizeMedium {
    height: 40px;
    font-size: 14px !important;
    font-weight: 500;
    border-radius: $radius6 !important;

    .MuiButton-iconSizeSmall {
      font-size: 14px !important;
      font-weight: 500;
    }
  }

  &.MuiButton-sizeLarge {
    height: 50px;
    padding: 0 24px;
    font-size: 14px !important;
    border-radius: $radius6;

    .MuiButton-iconSizeSmall {
      font-size: 14px !important;
    }
  }

  &.btn-stage-hold {
    height: 20px !important;
    padding: 0 6px;
    min-width: auto;
    font-size: 11px !important;
    border-radius: $radius4 !important;
  }
}

// Icon Button
.MuiIconButton-root {
  &.delete-icon-btn {
    .MuiSvgIcon-root {
      @include size(18px);
      color: var(--error);
    }
  }

  &.job-log-btn {
    @include size(40px);
    background: var(--primary);
    border-radius: $radius6;

    .MuiSvgIcon-root {
      @include size(20px);
    }

    &.Mui-disabled {
      background: var(--border-3) !important;
    }
  }

  &.job-stage-button {
    @include size(20px);
    background: var(--secondary-100);
    border-radius: $radius4;

    .MuiSvgIcon-root {
      @include size(12px);
      color: var(--secondary-700) !important;
    }
  }

  &.job-log-chat-count {
    height: 30px;
    width: auto;
    background: var(--secondary-100);
    border-radius: $radius4;
    min-width: 41px;
    min-height: 32px;

    .MuiSvgIcon-root {
      @include size(15px);
      color: var(--secondary-700) !important;
    }

    .chat-count {
      color: var(--secondary-700) !important;
    }
  }

  &.Mui-disabled {
    color: var(--text-4) !important;
    border: none !important;
  }
}

.btn-chat-expand {
  transform: rotate(90deg);
}

.btn-action-success {
  .MuiSvgIcon-root {
    color: var(--action-green) !important;
  }

  &:hover {
    color: var(--secondary-100) !important;
  }
}

// Table Edit Icon
.table-edit-icon {
  &.MuiIconButton-root {
    background-color: transparent !important;

    &:hover {
      background-color: var(--black-100) !important;
    }
  }
}

// Login Button
.btn-login {
  padding: 0 !important;
  height: auto !important;
  @include font(13px !important, normal, 600, var(--text-3) !important);
  background: unset !important;
  border-color: transparent !important;

  &.btn-resend {
    color: var(--primary) !important;
  }
}

// Dropdown button
.MuiMenu-list {
  border-radius: $radius4 !important;

  .MuiMenuItem-root {
    @include font(
      14px !important,
      24px,
      500 !important,
      var(--text-3) !important
    );
    .MuiCheckbox-root {
      padding: 6px;
      margin-right: 4px;
      .MuiSvgIcon-root {
        font-size: 1.15rem;
      }
    }
    .MuiTypography-root {
      @include font(
        14px !important,
        24px,
        500 !important,
        var(--text-3) !important
      );
    }

    &:hover,
    &:focus {
      color: var(--primary-700) !important;
    }
  }
}

// Icon Button
.icon-button {
  min-width: auto !important;
  max-width: 40px;
}
