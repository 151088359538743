.MuiChip-root {
  &.MuiChip-sizeMedium {
    &.MuiChip-filledSecondary {
      @include font(10px !important, normal, 600, var(--white) !important);
      background: var(--secondary);
    }

    .MuiChip-label {
      padding: 0 16px !important;
    }
  }

  &.MuiChip-outlinedDefault {
    background: var(--transparent) !important;
    border: $border-2 !important;

    .MuiChip-label {
      padding: 0 16px !important;
      @include font(10px, normal, 600, var(--text-2) !important);
    }
  }

  &.MuiChip-sizeSmall {
    height: 20px !important;
    @include font(12px, normal, 600, var(--text-3));
    border-radius: $radius4;
  }

  &.MuiChip-colorSuccess {
    color: var(--success);
    background: var(--success-bg);
  }

  &.MuiChip-colorError {
    color: var(--error);
    background: var(--error-bg);
  }

  &.MuiChip-colorInfo {
    color: var(--text-3);
    background: var(--black-80);
  }

  &.MuiChip-job {
    max-width: 105px;
    height: 20px !important;
    @include font(11px, normal, 600, var(--text-tertiary));
    border-radius: $radius4;

    .MuiChip-label {
      padding: 0 8px !important;
    }
  }

  &.chip-green {
    color: var(--green-dark);
    background: var(--green-light) !important;
  }

  &.chip-red {
    color: var(--red-dark) !important;
    background: var(--red-light) !important;
  }

  &.chip-yellow {
    color: var(--yellow-dark) !important;
    background: var(--yellow-light) !important;
  }

  &.chip-blue {
    color: var(--blue-primary) !important;
    background: var(--blue-light) !important;
  }

  &.chip-purple {
    color: var(--purple-dark) !important;
    background: var(--purple-light) !important;
  }

  &.chip-grey {
    color: var(--text-3) !important;
    background: var(--grey-light) !important;
  }

  &.chip-orange {
    color: var(--orange-primary) !important;
    background: var(--orange-light) !important;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1700px) {
  .MuiChip-job {
    max-width: 50px !important;
  }
}
