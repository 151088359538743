* {
  font-family: $font-family-primary !important;
  box-sizing: border-box;
}

//Product Colors
:root {
  --primary-A500: #001120;
  --primary-A400: #001c33;
  --primary-A300: #002646;
  --primary-A200: #003059;
  --primary-A100: #003b6c;
  --primary-700: #00457f;
  --primary-600: #266192;
  --primary-500: #4d7da5;
  --primary-400: #7399b9;
  --primary-300: #99b5cc;
  --primary-200: #bfd1df;
  --primary-150: #d9e3ec;
  --primary-100: #e6ecf2;

  --secondary-A500: #14200b;
  --secondary-A400: #1f3311;
  --secondary-A300: #2b4617;
  --secondary-A200: #375a1d;
  --secondary-A100: #426d24;
  --secondary-700: #4e802a;
  --secondary-600: #69934a;
  --secondary-500: #83a66a;
  --secondary-400: #9eb98a;
  --secondary-300: #b8ccaa;
  --secondary-200: #d3dfca;
  --secondary-100: #edf2ea;

  --black-900: #06172c;
  --black-800: #1f2e41;
  --black-700: #384556;
  --black-600: #515d6b;
  --black-500: #6a7480;
  --black-400: #838b96;
  --black-300: #9ba2ab;
  --black-200: #b4b9c0;
  --black-100: #cdd1d5;
  --black-90: #e6e8ea;
  --black-80: #eeeff0;
  --black-70: #f5f6f7;
  --black-60: #fafafb;

  --primary: #00457f;
  --secondary: #4e802a;
  --white: #ffffff;
  --black: #000000;
  --grey: #757575;
  --success: #198754;
  --action-green: #6eb33e;
  --success-bg: #d1ece9;
  --error: #dc3545;
  --error-secondary: #d75a57;
  --error-bg: #fce0df;
  --warn: #f7b726;
  --warn-bg: #fdf1d4;
  --link: #4d7da5;

  --primary-bg-1: #eef0f3;
  --primary-bg-2: #e1e5ea;

  --text-1: #06172c;
  --text-2: #384556;
  --text-3: #6a7480;
  --text-4: #9ba2ab;
  --text-5: #cdd1d5;

  --border-1: #6a7480;
  --border-2: #9ba2ab;
  --border-3: #cdd1d5;
  --border-4: #eeeff0;
  --border-5: #e6e8ea;
  --table-border: rgba(0, 69, 127, 0.1);
  --job-table-border: #e6e8ea;

  // Other color
  --transparent: rgba(255, 255, 255, 0);
  --loader-bg: rgba(255, 255, 255, 0.6);

  //Process colors

  --green-lighter: #f4fafa;
  --green-light: #d1ece9;
  --green-primary: #1a9e92;
  --green-dark: #178e83;
  --red-lighter: #fdf7f7;
  --red-light: #fce0df;
  --red-primary: #ef6461;
  --red-dark: #d75a57;
  --yellow-lighter: #fffbf4;
  --yellow-light: #fdf1d4;
  --yellow-primary: #f7b726;
  --yellow-dark: #dea522;
  --blue-lighter: #f3f8fe;
  --blue-light: #e7f2fe;
  --blue-primary: #0d78f2;
  --blue-dark: #0c6cda;
  --purple-lighter: #fbfaff;
  --purple-light: #eeeaff;
  --purple-primary: #a894ff;
  --purple-dark: #8676cc;
  --grey-lighter: #efefef;
  --grey-light: #e1e1e1;
  --grey-primary: #515151;
  --grey-dark: #414141;
  --orange-lighter: #fff4ed;
  --orange-light: #ffdcc3;
  --orange-primary: rgb(252, 106, 3);
  --orange-dark: #ec6200;

  // Job Stage
  --job-yellow-border: rgb(247, 183, 38, 0.2);
  --job-green-border: rgb(209, 236, 233, 0.2);
  --job-red-border: rgb(239, 100, 97, 0.2);
  --job-orange-border: rgb(252, 106, 3, 0.2);
  --job-purple-border: rgb(168, 148, 255, 0.2);
  --job-blue-border: rgb(13, 120, 242, 0.2);
}

html {
  height: -webkit-fill-available;
  height: -moz-available;
  height: -webkit-fill-available;
  height: fill-available;
}

body {
  background: var(--primary-bg-1) !important;
  font-size: 14px;
  margin: 0;
  padding: 0;
  font-family: $font-family-primary !important;
  color: var(--text-1);
  overflow-x: hidden;
  height: -webkit-fill-available;
  height: -moz-available;
  height: -webkit-fill-available;
  height: fill-available;
}

html,
body,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

a {
  text-decoration: none;
  outline: none;
  cursor: pointer;
}

p {
  margin: 0;
  word-break: break-word;
  overflow-wrap: break-word;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  padding: 0;
  outline: none;
  color: var(--text-1);
  overflow-wrap: break-word;
}

button {
  outline: none;
  cursor: pointer;
  outline-color: transparent;
  appearance: none;
}

button:focus,
button > .focus {
  outline: none;
  box-shadow: none;
  cursor: pointer;
}

::selection {
  color: var(--black);
  background: var(--primary-100);
}

//App Classes

.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.appearance-none {
  @include appearance(none);
}

.disabled {
  cursor: not-allowed;
  @include userSelect(none);
}

.text-disabled {
  color: var(--text-4);
  @include userSelect(none);
  cursor: not-allowed;
}

.overflow-hidden {
  overflow: hidden;
}

.cursor-pointer {
  cursor: pointer;
}

.overflow-y-scroll {
  overflow-y: auto;
}

.h-490 {
  height: 490px;
}

.h-250 {
  height: 250px;
}

.h-230 {
  height: 230px;
}

.h-200 {
  height: 200px;
}

.horizontal-center {
  text-align: center;
}
