.MuiPagination-root {
  .MuiPaginationItem-root {
    border: 1px solid var(--black-200) !important;
    font-size: 13px !important;
    color: var(--text-2) !important;
    min-height: 32px;
    border-radius: $radius4;

    &.Mui-selected {
      color: var(--primary-700) !important;
      background: var(--primary-100) !important;
      border-color: var(--primary-600) !important;
    }

    &.Mui-disabled {
      color: var(--text-4) !important;
      background: var(--black-90) !important;
      border-color: transparent !important;
      opacity: 1 !important;
    }
  }

  .MuiPagination-ul {
    flex-wrap: nowrap;

    li {
      &:first-child {
        flex-basis: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        >button::after {
          margin-left: 10px;
          content: 'Back';
        }
      }

      &:last-child {
        flex-basis: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        >button::before {
          margin-right: 10px;
          content: 'Next';
        }
      }
    }
  }
}
