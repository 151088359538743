.notification-popover-container {
  width: 100%;
}

.notification-body {
  max-height: 300px;
}

.notificationEmpty-body {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

// .notification-container {
//   height: 80px;
// }

.notification-header-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: white;
}

.notification-container:hover {
  background-color: #f3f8fc;
}

.unread-notification {
  background-color: #e6ecf2;
}

.notification-date-bg {
  background-color: #bfd1df;
}
