//Font Size
$fontSizeConfig: 9,
10,
11,
12,
13,
14,
15,
16,
18,
20,
22,
24,
28,
30,
36,
32,
40,
48;

@each $font in $fontSizeConfig {
  .f-#{nth($font, 1)} {
    font-size: #{nth($font, 1)}px !important;
  }
}

//Font-weight
$fontWeightConfig: 200,
300,
400,
500,
600,
700,
800,
bold;

@each $weight in $fontWeightConfig {
  .f-w-#{$weight} {
    font-weight: $weight;
  }
}

//Line-height
$lineHeightConfig: 14,
16,
18,
20,
22,
24,
26,
32;

@each $height in $lineHeightConfig {
  .l-h-#{nth($height, 1)} {
    line-height: #{nth($height, 1)}px;
  }
}

$lineHeightConfig1: normal,
auto,
1;

@each $height in $lineHeightConfig1 {
  .l-h-#{nth($height, 1)} {
    line-height: #{nth($height, 1)};
  }
}

//letter-space
$letterSpaceConfig: 'normal''normal',
'1''1px';

@each $space in $letterSpaceConfig {
  .l-s-#{nth($space, 1)} {
    letter-spacing: #{nth($space, 2)};
  }
}

// color
$textColors: 'primary'var(--text-1),
'secondary'var(--text-2),
'tertiary'var(--text-3),
'hint'var(--text-4),
'light'var(--text-5),
'icon'var(--black-200),
'color-primary'var(--primary),
'color-secondary'var(--secondary),
'color-secondary-5'var(--secondary-500),
'color-secondary-7'var(--secondary-700),
'black'var(--black),
'white'var(--white),
'success'var(--success),
'error'var(--error),
'warn'var(--warn),
'link'var(--link),
'primary-4'var(--primary-400),
'primary-6'var(--primary-600),
'primary-7'var(--primary-700),
'black-1'var(--black-100),
'black-3'var(--black-300),
'black-4'var(--black-400),
'black-9'var(--black-900),
'black-5'var(--black-500),
'black-7'var(--black-700),
'black-8'var(--black-800);

@each $color in $textColors {
  .text-#{nth($color, 1)} {
    color: nth($color, 2) !important;
  }
}

//Background color
$bgColors: 'primary'var(--primary),
'secondary'var(--secondary),
'secondary-1'var(--secondary-100),
'header'var(--primary-A400) 'primary-1'var(--primary-bg-1),
'primary-2'var(--primary-bg-2),
'black'var(--black),
'black-400'var(--black-400),
'black-90'var(--black-90),
'white'var(--white),
'note'var(--black-70),
'transparent'transparent,
'primary-100'var(--primary-100);

@each $color in $bgColors {
  .bg-#{nth($color, 1)} {
    background-color: nth($color, 2) !important;
  }
}

//Hover colors
@each $color in $textColors {
  .text-hover-#{nth($color, 1)} {

    &:hover,
    &:focus {
      cursor: pointer;
      color: nth($color, 2) !important;
    }
  }
}

@each $color in $bgColors {
  .bg-hover-#{nth($color, 1)} {

    &:hover,
    &:focus {
      cursor: pointer;
      background: nth($color, 2) !important;
    }
  }
}

$hoverOpacity: 0 0,
75 0.75;

@each $opacity in $hoverOpacity {
  .o-#{nth($opacity, 1)} {

    &:hover,
    &:focus {
      cursor: pointer;
      opacity: nth($opacity, 2);
    }
  }
}

//icon
$iconConfig: 12,
16,
18,
20,
22,
24;

@each $icon in $iconConfig {
  .i-#{nth($icon, 1)} {
    font-size: nth($icon, 1) + px !important;
  }
}

//Width
$sizeWidth: '100'100%,
'75'75%,
'50'50%,
'25'25%,
auto auto;

@each $size in $sizeWidth {
  .w-#{nth($size, 1)} {
    width: nth($size, 2) !important;
  }
}

$minMaxWidth: 40,
80,
90,
100,
105,
110,
120,
140,
155,
160,
200,
204,
212,
300,
315,
370,
420,
480,
530,
600,
720;

@each $size in $minMaxWidth {
  .mw-#{nth($size, 1)} {
    max-width: #{nth($size, 1)}px !important;
  }
}

@each $size in $minMaxWidth {
  .minw-#{nth($size, 1)} {
    min-width: #{nth($size, 1)}px !important;
  }
}

//Height
$sizeHeight: '100'100%,
'75'75%,
'50'50%,
'25'25%;

@each $size in $sizeHeight {
  .h-#{nth($size, 1)} {
    height: nth($size, 2);
  }
}

//Cursor
$cursorType: pointer,
default,
disabled,
none;

@each $cursor in $cursorType {
  .cursor-#{nth($cursor, 1)} {
    cursor: nth($cursor, 1);
  }
}

//Image Size
$imageSizes: 30,
36,
60,
80,
400;

@each $image in $imageSizes {
  .image-#{nth($image, 1)} {
    width: nth($image, 1) + px;
    height: nth($image, 1) + px;
  }
}

//Object Fit
$objectFit: contain,
cover,
fill,
inherit,
unset,
none;

@each $obj in $objectFit {
  .object-#{nth($obj, 1)} {
    object-fit: nth($obj, 1);
  }
}

//Padding and Margin

$marginAlign: 'auto'auto,
'x-auto'auto,
'y-auto'auto;

@each $margin in $marginAlign {
  .m-#{nth($margin, 1)} {
    margin: nth($margin, 2);
  }
}

$spacerOrigin: 'm'margin,
'p'padding;
$spacerPosition: 't'top,
'b'bottom,
'r'right,
'l'left;
$spacerPositionXY: 'x'left right,
'y'top bottom;
$spacerSizing: 0,
2,
3,
4,
6,
8,
10,
12,
14,
15,
16,
18,
20,
22,
24,
28,
30,
32,
36,
40,
48,
60;

@each $origin in $spacerOrigin {
  @each $position in $spacerPositionXY {
    @each $sizing in $spacerSizing {
      .#{nth($origin, 1)}#{nth($position, 1)}-#{nth($sizing, 1)} {
        #{nth($origin, 2)}-#{nth($position, 2)}: nth($sizing, 1) + px !important;
        #{nth($origin, 2)}-#{nth($position, 3)}: nth($sizing, 1) + px !important;
      }
    }
  }
}

@each $origin in $spacerOrigin {
  @each $sizing in $spacerSizing {
    .#{nth($origin, 1)}-#{nth($sizing, 1)} {
      #{nth($origin, 2)}: nth($sizing, 1) + px !important;
    }
  }
}

@each $origin in $spacerOrigin {
  @each $position in $spacerPosition {
    @each $sizing in $spacerSizing {
      .#{nth($origin, 1)}#{nth($position, 1)}-#{nth($sizing, 1)} {
        #{nth($origin, 2)}-#{nth($position, 2)}: nth($sizing, 1) + px !important;
      }
    }
  }
}

// Border Style
$borderStyle: border,
border-top,
border-bottom,
border-left,
border-right;

@each $borderValue in $borderStyle {
  .#{nth($borderValue, 1)} {
    #{nth($borderValue, 1)}: 1px solid var(--border-3);
  }

  .#{nth($borderValue, 1)}-light {
    #{nth($borderValue, 1)}: 1px solid var(--border-4);
  }

  .#{nth($borderValue, 1)}-exlight {
    #{nth($borderValue, 1)}: 1px solid var(--border-5);
  }

  .#{nth($borderValue, 1)}-dark {
    #{nth($borderValue, 1)}: 1px solid var(--black-600);
  }

  .#{nth($borderValue, 1)}-darken {
    #{nth($borderValue, 1)}: 1px solid var(--black-90);
  }
}

@each $borderValue in $borderStyle {
  .#{nth($borderValue, 1)}-0 {
    #{nth($borderValue, 1)}: 0 !important;
  }
}

//Text Style
$txtAlign: 'left'left,
'center'center,
'right'right;

@each $align in $txtAlign {
  .text-#{nth($align, 1)} {
    text-align: nth($align, 2);
  }
}

$txtTransform: uppercase,
lowercase,
capitalize;

@each $transform in $txtTransform {
  .text-#{$transform} {
    text-transform: $transform !important;
  }
}

$wordBreak: break-word,
break-all,
none,
normal;

@each $break in $wordBreak {
  .word-#{nth($break, 1)} {
    word-break: nth($break, 1);
  }
}

$whiteSpace: wrap,
nowrap,
none,
unset;

@each $space in $whiteSpace {
  .w-#{nth($space, 1)} {
    white-space: nth($space, 1);
  }
}

//Display Classes
$displayClasses: flex,
block,
inline-block,
none,
grid,
inline-grid;

@each $display in $displayClasses {
  .d-#{$display} {
    display: $display !important;
  }
}

$flexWrap: wrap,
no-wrap,
none,
warp-reserve,
unset;

@each $wrap in $flexWrap {
  .flex-#{$wrap} {
    flex-wrap: $wrap;
  }
}

$flexDirection: row,
column,
row-reverse,
column-reverse;

@each $direction in $flexDirection {
  .flex-#{nth($direction, 1)} {
    flex-direction: nth($direction, 1);
  }
}

$flexAlign: 'start'flex-start,
'center'center,
'end'flex-end,
'between'space-between,
'stretch'stretch;

@each $align in $flexAlign {
  .align-#{nth($align, 1)} {
    align-items: nth($align, 2);
  }
}

$flexAlign: 'start'flex-start,
'center'center,
'end'flex-end,
'between'space-between,
'stretch'stretch;

@each $align in $flexAlign {
  .align-content-#{nth($align, 1)} {
    align-items: nth($align, 2);
  }
}

$flexContent: 'start'flex-start,
'center'center,
'end'flex-end,
'between'space-between,
'around'space-around,
'evenly'space-evenly;

@each $justify in $flexContent {
  .justify-#{nth($justify, 1)} {
    justify-content: nth($justify, 2) !important;
  }
}

$placeItems: 'start'start,
'center'center,
'end'end,
'start-self''start-self',
'start-end''start-end';

@each $place in $placeItems {
  .place-#{nth($place, 1)} {
    place-items: nth($place, 2);
  }
}

//Column Gap

$sizesGap: 0,
6,
8,
12,
16,
20,
24,
32;

@each $gap in $sizesGap {
  .g-#{nth($gap, 1)} {
    gap: nth($gap, 1) + px;
    -webkit-gap: nth($gap, 1) + px;
    grid-gap: nth($gap, 1) + px;
  }

  .c-g-#{nth($gap, 1)} {
    column-gap: nth($gap, 1) + px;
    -webkit-column-gap: nth($gap, 1) + px;
    grid-column-gap: nth($gap, 1) + px;
  }

  .r-g-#{nth($gap, 1)} {
    row-gap: nth($gap, 1) + px;
    -webkit-row-gap: nth($gap, 1) + px;
    grid-row-gap: nth($gap, 1) + px;
  }
}

//Position
$positionSide: relative,
absolute,
sticky,
fixed,
unset;

@each $position in $positionSide {
  .p-#{nth($position, 1)} {
    position: nth($position, 1);
  }
}

//Cursor classes
$cursorMouse: pointer,
not-allowed,
none,
default;

@each $cursor in $cursorMouse {
  .cursor-#{nth($cursor, 1)} {
    cursor: nth($cursor, 1) !important;
  }
}

//Border radius
$borderRadius: 0,
4,
5,
6,
8,
10,
12,
20;

@each $radius in $borderRadius {
  .radius-#{nth($radius, 1)} {
    border-radius: nth($radius, 1) + px !important;
  }
}

//Flex Classes
$flexFlow: 1,
2,
3;

@each $flow in $flexFlow {
  .flex-#{$flow} {
    flex: $flow;
  }
}

$responsiveSize: '300px''600px''xs',
'768px''1024px''sm',
'1024px''1280px''md',
'1280px''1440px''lg',
'1440px''1920px''xl',
'1920px''2800px''xxl';

@each $responsive in $responsiveSize {
  @media only screen and (min-width: #{nth($responsive,1)}) and (max-width: #{nth($responsive,2)}) {
    @each $gap in $sizesGap {
      .#{nth($responsive, 3)}\:g-#{nth($gap, 1)} {
        gap: nth($gap, 1) + px;
        -webkit-gap: nth($gap, 1) + px;
        grid-gap: nth($gap, 1) + px;
      }

      .#{nth($responsive, 3)}\:c-g-#{nth($gap, 1)} {
        column-gap: nth($gap, 1) + px;
        -webkit-column-gap: nth($gap, 1) + px;
        grid-column-gap: nth($gap, 1) + px;
      }

      .#{nth($responsive, 3)}\:r-g-#{nth($gap, 1)} {
        row-gap: nth($gap, 1) + px;
        -webkit-row-gap: nth($gap, 1) + px;
        grid-row-gap: nth($gap, 1) + px;
      }
    }

    @each $radius in $borderRadius {
      .#{nth($responsive, 3)}\:radius-#{nth($radius, 1)} {
        border-radius: nth($radius, 1) + px !important;
      }
    }

    @each $color in $bgColors {
      .#{nth($responsive, 3)}\:bg-#{nth($color, 1)} {
        background-color: nth($color, 2) !important;
      }
    }

    .#{nth($responsive, 3)}\:border-none {
      border: none !important;
    }

    @each $size in $sizeWidth {
      .#{nth($responsive, 3)}\:w-#{nth($size, 1)} {
        width: nth($size, 2) !important;
      }
    }

    @each $size in $minMaxWidth {
      .#{nth($responsive, 3)}\:mw-#{nth($size, 1)} {
        max-width: #{nth($size, 1)}px !important;
      }
    }

    @each $size in $minMaxWidth {
      .#{nth($responsive, 3)}\:minw-#{nth($size, 1)} {
        min-width: #{nth($size, 1)}px !important;
      }
    }

    .#{nth($responsive, 3)}\:minw-none {
      max-width: unset !important;
    }

    .#{nth($responsive, 3)}\:mw-none {
      min-width: unset !important;
    }

    .#{nth($responsive, 3)}\:radius-none {
      border-radius: unset !important;
    }

    @each $align in $txtAlign {
      .#{nth($responsive, 3)}\:text-#{nth($align, 1)} {
        text-align: nth($align, 2) !important;
      }
    }

    @each $weight in $fontWeightConfig {
      .#{nth($responsive, 3)}\:f-w-#{$weight} {
        font-weight: $weight;
      }
    }

    @each $font in $fontSizeConfig {
      .#{nth($responsive, 3)}\:f-#{nth($font, 1)} {
        font-size: #{nth($font, 1)}px !important;
      }
    }

    @each $height in $lineHeightConfig {
      .#{nth($responsive, 3)}\:l-h-#{nth($height, 1)} {
        line-height: #{nth($height, 1)}px;
      }
    }

    @each $origin in $spacerOrigin {
      @each $position in $spacerPositionXY {
        @each $sizing in $spacerSizing {
          .#{nth($responsive, 3)}\:#{nth($origin, 1)}#{nth($position, 1)}-#{nth($sizing, 1)} {
            #{nth($origin, 2)}-#{nth($position, 2)}: nth($sizing, 1) + px !important;
            #{nth($origin, 2)}-#{nth($position, 3)}: nth($sizing, 1) + px !important;
          }
        }
      }
    }

    @each $origin in $spacerOrigin {
      @each $sizing in $spacerSizing {
        .#{nth($responsive, 3)}\:#{nth($origin, 1)}-#{nth($sizing, 1)} {
          #{nth($origin, 2)}: nth($sizing, 1) + px !important;
        }
      }
    }

    @each $origin in $spacerOrigin {
      @each $position in $spacerPosition {
        @each $sizing in $spacerSizing {
          .#{nth($responsive, 3)}\:#{nth($origin, 1)}#{nth($position, 1)}-#{nth($sizing, 1)} {
            #{nth($origin, 2)}-#{nth($position, 2)}: nth($sizing, 1) + px !important;
          }
        }
      }
    }

    @each $display in $displayClasses {
      .d-#{nth($responsive, 3)}\:#{$display} {
        display: $display !important;
      }
    }

    @each $wrap in $flexWrap {
      .#{nth($responsive, 3)}\:flex-#{$wrap} {
        flex-wrap: $wrap;
      }
    }

    @each $direction in $flexDirection {
      .#{nth($responsive, 3)}\:flex-#{nth($direction, 1)} {
        flex-direction: nth($direction, 1);
      }
    }

    @each $align in $flexAlign {
      .#{nth($responsive, 3)}\:align-#{nth($align, 1)} {
        align-items: nth($align, 2);
      }
    }

    @each $align in $flexAlign {
      .#{nth($responsive, 3)}\:align-content-#{nth($align, 1)} {
        align-items: nth($align, 2);
      }
    }

    @each $justify in $flexContent {
      .#{nth($responsive, 3)}\:justify-#{nth($justify, 1)} {
        justify-content: nth($justify, 2) !important;
      }
    }

    @each $place in $placeItems {
      .#{nth($responsive, 3)}\:place-#{nth($place, 1)} {
        place-items: nth($place, 2);
      }
    }
  }
}

@include only-mobile-device {
  .minwm-unset {
    min-width: unset !important;
  }

  .mwm-unset {
    max-width: unset !important;
  }
}

//Margin and Space

.space-ml-12> :not([hidden])~ :not([hidden]) {
  margin-left: 24px;
}

.space-my-4> :not([hidden])~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(4px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(4px * var(--tw-space-y-reverse));
}

.space-my-6> :not([hidden])~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(6px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(6px * var(--tw-space-y-reverse));
}

.space-my-12> :not([hidden])~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(12px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(12px * var(--tw-space-y-reverse));
}
