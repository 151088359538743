.dropzone {
  background-color: #f0f0f0;
  border-radius: 0.3vh;
  height: 230px;
  border-style: dashed;
  border-color: lightgray;
  position: relative;
  width: 100%;
  display: table;
}

.dropzone-vertical-center-label {
  text-align: center;
  vertical-align: middle;
  display: table-cell;
  color: #b5b5b5;
}

.no-files-v-align {
  padding-top: 230px;
}

.no-files-f-color {
  color: #747474;
}

.download-btn {
  color: #00457f;
}

.file-name {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-displayName {
  width: 30%;
  align-content: flex-start;
  justify-content: flex-start;

  a:hover {
    text-decoration: underline;
  }
}

.copy-clipboard {
  width: 70px;
}

.generateButton {
  width: 155px;
  margin-right: 10px;
}

.file-owner-name {
  width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
