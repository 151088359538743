.dashboard,
.clients,
.dataQuote,
.history,
.invoice,
.job-log,
.job-stage,
.scopes,
.users {
  &-page {
    height: 100vh;
  }
  &-header {
    height: 64px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
  }
  &-content-section {
    padding-top: 64px;
    @include sizing(100%, calc(100vh - 64px));
  }
  &-SideMenu {
    position: fixed;
    left: 0;
    bottom: 0;
    max-width: 232px;
    @include sizing(100%, calc(100vh - 64px));
    overflow: auto;
  }
  &-content {
    margin-left: 232px;
    @include sizing(calc(100vw - 232px), calc(100vh - 64px));
    overflow: auto;
  }
}

.login-page,
.forgot-password-page,
.create-password-page,
.login-mfa-verification-page {
  &-section {
    height: 100vh;
  }
  &-content {
    @include sizing(100%, calc(100vh - 60px));
    overflow: auto;
  }
  &-footer {
    height: 60px;
  }
}

.verification-time{
  position: absolute;
  right: 0;
}

@media only screen and (min-width: 1024px) and (max-width: 1366px) {
  .client-text{
    font-size: 13px !important;
  }
}