.MuiTable-root {
  .MuiTableRow-head {
    border: 1px solid var(--table-border);
  }

  .MuiTableBody-root {
    .MuiTableRow-root {
      background: var(--white);

      &:hover {
        background: var(--black-70);

        .table-icon {
          visibility: visible !important;
        }
      }

      &:last-child {
        .MuiTableCell-body {
          &:nth-child(1) {
            border-bottom-left-radius: 4px !important;
          }
        }
      }
    }
  }

  .MuiTableCell-root {
    padding: 8px 12px !important;

    &.MuiTableCell-head {
      text-transform: uppercase;
      background: var(--black-70);
      @include font(12px !important, 23px !important, 600, var(--text-4));
      border-top: 1px solid var(--table-border) !important;
      border-bottom: none !important;
      box-shadow: 0 -6px var(--primary-bg-1) !important;

      &:nth-child(1) {
        border-top-left-radius: 4px !important;
        border-left: 1px solid var(--table-border) !important;
      }

      &:nth-child(7) {
        border-top-right-radius: 4px !important;
        border-right: 1px solid var(--table-border) !important;
      }
    }

    &.MuiTableCell-body {
      border-top: 1px solid var(--table-border) !important;
      background: transparent !important;
      @include font(13px !important, 23px !important, 500, var(--text-3));
      border-bottom: none !important;

      &:nth-child(1) {
        font-weight: 700 !important;
        color: var(--text-1) !important;
      }
    }
  }
}

// Table Size
.client-table-container {
  max-height: calc(100vh - 196px);
}

.data-table-container {
  max-height: calc(100vh - 196px);

  .MuiTableCell-head {
    height: 40px;

    &:nth-child(1) {
      border-top-left-radius: 4px !important;
      border-left: 1px solid var(--table-border) !important;
    }

    &:nth-child(7) {
      border-top-right-radius: 4px !important;
      border-right: 1px solid var(--table-border) !important;
    }
  }

  .MuiTableCell-body {
    height: 44px;
  }

  &.job-id-section {
    max-width: 110px;
    max-height: unset !important;
    .MuiTableCell-head {
      &:nth-child(1) {
        border-top-left-radius: 4px !important;
        border-right: 1px solid var(--table-border) !important;
      }
    }
  }

  &.job-data-section {
    max-height: unset !important;
    .MuiTableCell-head {
      &:nth-child(1) {
        border-top-left-radius: 0 !important;
      }

      &:nth-child(5) {
        border-right: 1px solid var(--table-border) !important;
      }
    }

    .MuiTableCell-body {
      &:nth-child(1) {
        font-weight: 500 !important;
        color: var(--text-3) !important;
      }
    }
  }

  &.job-quota-section {
    max-width: 480px;
    max-height: unset !important;

    .MuiTableCell-head {
      &:nth-child(1) {
        border-top-left-radius: 0 !important;
      }

      &:nth-child(4) {
        border-top-right-radius: 4px !important;
        border-right: 1px solid var(--table-border) !important;
      }
    }

    .MuiTableCell-body {
      &:nth-child(1) {
        font-weight: 500 !important;
        color: var(--text-3) !important;
      }
    }
  }

  @media only screen and (min-width: 1024px) and (max-width: 1424px) {
    .MuiTableCell-head,
    .MuiTableCell-body {
      height: 63px;
    }
  }
}

.table-column-color {
  position: relative;

  &::before {
    content: '';
    @include positionTopLeft(absolute, 0, 0);
    @include sizing(100%, 30px);
    background: var(--primary-bg-1) !important;
    z-index: 0;
  }

  .column-text {
    position: relative;
    z-index: 1;
  }
}

// TODO - check and remove
// .table-container {
//   overflow: unset !important;
// }

.clients-table {
  .MuiTableBody-root {
    .MuiTableRow-root .MuiTableCell-root:last-child {
      display: flex;
      justify-content: end;
    }
  }
}

.invoice-table {
  .invoice-table-container {
    height: calc(100vh - 272px);
  }
}

.scopes-table,
.clients-table,
.data-quote-table {
  .table-content {
    height: calc(100vh - 196px);
    overflow: auto;
  }
}

.scopes-table {
  .MuiTableCell-head {
    font-size: 11px !important;
  }
}

.scopes-table {
  .MuiTableBody-root {
    .MuiTableRow-root .MuiTableCell-root:first-child {
      max-width: 90px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.users-table {
  .table-content {
    height: calc(100vh - 266px);
    overflow: auto;
    .MuiTableCell-body {
      &:nth-child(2) {
        font-weight: 700 !important;
        color: var(--text-1) !important;
      }
    }
  }
}

.awf-table {
  .table-content {
    height: calc(100vh - 266px);
    overflow: auto;
    .MuiTableCell-body {
      &:nth-child(1) {
        font-weight: 700 !important;
        color: var(--text-1) !important;
      }
    }
  }
}

.table {
  &-five-radius {
    .MuiTableCell-head {
      &:nth-child(5) {
        border-top-right-radius: 4px !important;
        border-right: 1px solid var(--table-border) !important;
      }
    }
  }

  &-six-radius {
    .MuiTableCell-head {
      &:nth-child(6) {
        border-top-right-radius: 4px !important;
        border-right: 1px solid var(--table-border) !important;
      }
    }
  }

  &-seven-radius {
    .MuiTableCell-head {
      &:nth-child(7) {
        border-top-right-radius: 4px !important;
        border-right: 1px solid var(--table-border) !important;
      }
    }
  }
}

.table-empty {
  .table-content {
    height: auto !important;
  }
}

.table-icon {
  margin-right: 12px;
  visibility: hidden;
  width: 28px;
  height: 28px;
  background: var(--primary-100);
}
