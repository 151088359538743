// Modal content
.MuiDialog-container {
  .MuiDialogTitle-root {
    position: relative;
    padding: 16px 24px;
    .modal-close-icon {
      @include positionTopRight(absolute, 24px, 24px);
    }
  }
  .MuiDialogContent-root {
    padding: 16px 24px !important;
  }
  .MuiDialogActions-root {
    height: 74px;
    background: var(--white);
    padding: 16px 24px;
  }
}

.MuiDialog-root {
  .MuiDialog-paper {
    border-radius: $radius8;
    background: var(--white);
  }
  &.large-modal {
    .MuiDialog-container .MuiDialog-paper {
      max-width: 700px;
      width: 100%;
    }
  }
  &.small-modal {
    .MuiDialog-container .MuiDialog-paper {
      max-width: 460px;
      width: 100%;
    }
  }
  &.portfolio-modal{
    .MuiDialogTitle-root {
      padding: 24px 24px 12px !important;
    }
    .MuiDialogContent-root {
      padding: 12px 24px !important;
    }
    .MuiDialogActions-root {
      padding: 12px 24px 24px !important;
    }
  }
}

// //Mui menu error
.dropdown-menu {
  z-index: 10;
  .MuiPaper-root {
    min-width: 200px;
    border-radius: $radius6;
    background: var(--white);
    .MuiMenuItem-root {
      @include font(14px !important, normal, bold, var(--text-2) !important);
      &:hover {
        background: var(--primary-bg-3);
      }
    }
  }
}
