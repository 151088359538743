.chat-widget {
  &-section {
    @include positionBottomRight(fixed, 16px, 24px);
  }
  &-action {
    @include size(54px);
    background: var(--primary);
    border-radius: $circle-radius;
    filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.25));
  }
  &-container {
    transition: all 0.5s ease-in-out;
    display: none;
  }
  &-active {
    display: block !important;
  }
  &-popup {
    margin-bottom: 8px;
    max-width: 360px;
    min-width: 360px;
    width: 100%;
    background: var(--white);
    border-radius: $radius6;
    filter: drop-shadow(0px 0px 4px rgba(56, 69, 86, 0.03)) drop-shadow(0px 6px 18px rgba(56, 69, 86, 0.16));
  }
  &-popup-extend {
    max-width: 650px !important;
    min-width: 650px !important;
  }
  &-header {
    height: 54px;
    border-bottom: 1px solid #e6e8ea;
  }
  &-content {
    height: calc(470px - 60px);
    overflow: auto;
  }
  &-footer {
    height: 60px;
  }
}
