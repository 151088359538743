.SideMenu-link {
  &-active {
    background: var(--primary-150);
    .MuiSvgIcon-root,
    p {
      color: var(--primary-A100) !important;
    }
  }
  &:hover,
  &:focus {
    background: var(--primary-100);
  }
}
