.MuiAvatar-root {
  @include font(14px !important, normal, bold, var(--text-1) !important);
  background: var(--primary-bg-1) !important;
  &.bordered {
    border: $border-avatar;
  }
  &.large-bordered {
    border: $border-avatar-large;
  }
  &.large {
    font-size: 22px !important;
    @include size(100px);
  }
  &.medium {
    @include size(50px);
  }
  &.small {
    @include size(36px);
  }
  .MuiAvatar-img {
    object-fit: contain !important;
  }
}

// Loader
.loader-container {
  @include positionFull(fixed, 0);
  @include sizing(100%, 100vh);
  background-color: var(--loader-bg);
  z-index: 1000;
  .MuiCircularProgress-svg {
    color: var(--primary) !important;
  }
  &.job-stage-loader {
    top: unset !important;
    height: 120px !important;
    background: none !important;
  }
}
