.MuiTabs-root {
  .MuiTabs-scroller {
    border-bottom: $border-2;
  }
  .MuiTab-root {
    padding: 10px 0;
    margin-right: 24px;
    min-height: 40px;
    @include font(14px, 20px, 600, var(--text-3));
    text-transform: unset;
    .MuiTouchRipple-root {
      display: none !important;
    }
    &.Mui-selected {
      color: var(--primary) !important;
      font-weight: 700 !important;
    }
  }
  .MuiTabs-indicator {
    height: 2px;
    bottom: 8px !important;
    border-top-left-radius: $radius4;
    border-top-right-radius: $radius4;
    background: var(--primary) !important;
  }
  .MuiTabs-scroller {
    border-bottom: none !important;
  }
}
.MuiTabPanel-root {
  padding: 16px 0 !important;
}

.user-tab-container {
}
