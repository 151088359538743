$font-family-primary: "Inter";

//Border Radius
$radius4: 4px;
$radius5: 5px;
$radius6: 6px;
$radius8: 8px;
$radius10: 10px;
$radius12: 12px;
$radius20: 20px;
$round-radius: 50px;
$circle-radius: 50%;

//layouts
$header-height: 64px;
$SideMenu-width: 232px;

//App Filter
$header-blur: blur(10px);
$backdrop-blur: blur(5px);

//borders
$border-transparent: 1px solid transparent;
$border-1: 1px solid var(--border-1);
$border-2: 1px solid var(--border-2);
$border-3: 1px solid var(--border-3);
$border-4: 1px solid var(--border-4);
$border-5: 1px solid var(--border-5);
$border-primary: 1px solid var(--primary);

$border-modal-outline: 2px solid var((--border-3));
$border-avatar: 2px solid var(--border-3);
$border-avatar-large: 3px solid var((--border-3));
$border-input: 1px solid var(--border-3);
$border-disabled: 1px solid var(--border-3);
