.MuiSwitch-root {
  .MuiSwitch-track {
    background: var(--white);
    border: $border-3;
    opacity: 1 !important;
  }
  .MuiTouchRipple-roo {
    display: none;
  }
  .MuiSwitch-thumb {
    box-shadow: none;
    background: var(--black-400) !important;
  }
  .Mui-checked {
    color: var(--white) !important;
    & + .MuiSwitch-track {
      background: var(--primary) !important;
      border: $border-primary !important;
    }
    .MuiSwitch-thumb {
      background: var(--white) !important;
    }
  }
  &.MuiSwitch-sizeMedium {
    width: 40px;
    height: 16px;
    padding: 0 !important;
    .MuiSwitch-switchBase {
      padding: 0 0px !important;
    }
    .MuiSwitch-thumb {
      width: 12px;
      height: 12px;
      margin: 2px;
    }
    .MuiSwitch-input {
      left: -200%;
      width: 500%;
    }
    .MuiSwitch-track {
      border-radius: 20px;
    }
    .MuiSwitch-switchBase {
      &.Mui-checked {
        transform: translateX(24px) !important;
      }
    }
  }
}
