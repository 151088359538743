@font-face {
  font-family: $font-family-primary;
  src: url("../fonts/Inter-ExtraLight.ttf") format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: $font-family-primary;
  src: url("../fonts/Inter-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: $font-family-primary;
  src: url("../fonts/Inter-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: $font-family-primary;
  src: url("../fonts/Inter-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: $font-family-primary;
  src: url("../fonts/Inter-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: $font-family-primary;
  src: url("../fonts/Inter-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: $font-family-primary;
  src: url("../fonts/Inter-ExtraBold.ttf") format("truetype");
  font-weight: 800;
}
