.MuiDrawer-root {
  .MuiPaper-root {
    width: 100%;
    max-width: 750px;
    background: var(--primary-bg-1);
    border-top-left-radius: $radius8;
    border-bottom-left-radius: $radius8;
  }
}
.side-drawer {
  &-section {
    height: 100vh;
  }
  &-header {
    background: var(--white);
    height: 68px;
  }
  &-content {
    height: calc(100vh - 138px);
    overflow: auto;
  }
  &-footer {
    background: var(--white);
    height: 70px;
  }
  &-sortBy {
    height: auto;
  }
  &-content-sortBy {
    height: 130px !important;
  }
}

.job-dropdown-menu {
  .MuiPaper-root {
    width: 126px;
    .MuiMenu-list {
      padding: 4px !important;
      border-radius: $radius4 !important;
      .MuiMenuItem-root {
        padding: 0 8px !important;
        @include font(
          14px !important,
          36px,
          500 !important,
          var(--text-3) !important
        );
        &:hover,
        &:focus {
          color: var(--primary-700) !important;
          background: var(--primary-100) !important;
        }
      }
    }
  }
}
